import React from "react"
import Title from "./Title"
import {Link} from 'gatsby'

import * as styles from "../css/apps.module.css"
import trafficwizard_img from "../images/trafficsigns/header.png"
import bluescope_img from "../images/bluescope/bluescope_header.png"
import actiontimer_img from "../images/actiontimer/macOS_desktop_updated.png"

import iOSAppStoreBadge from '../images/bluescope/App_Store_Badge_US.svg'
import macAppStoreBadge from '../images/actiontimer/Mac_App_Store_Badge_US.svg'

const Apps = () => {
    
    const appStoreCampaignUrlTrafficWizard = "https://apps.apple.com/app/apple-store/id1543287536?pt=121290356&ct=malaudhome&mt=8"
    const appStoreCampaignUrlBlueScope = "https://apps.apple.com/app/apple-store/id1530096924?pt=121290356&ct=malaudhome&mt=8"
    const appStoreCampaignUrlActionTimer = "https://apps.apple.com/app/apple-store/id1518277131?pt=121290356&ct=malaudhome&mt=8"

    return (
        <>
        <section className={styles.about}>
       
        <Title title="Software" subtitle="Apps"/>
       
        {/* ***************** */}
        {/* BlueScope for iOS */}
            <div className={styles.aboutCenter}>
                <article className={styles.aboutImg}>
                    <div className={styles.imgContainer}>
                        <Link to="/bluescope">
                            <img src={bluescope_img} alt="BlueScope for iOS" />
                        </Link>
                    </div>
                </article>
            
                <article className={styles.aboutInfo}>
                    {/* <h4 style={{color: '#0398F4'}}>BlueScope for iOS</h4> */}
                    <h4>BlueScope® for iOS</h4>
                    <p>Advanced Bluetooth Device Tracker</p>
                    <p>BlueScope is the most advanced Bluetooth Tracker & Device Finder available for iOS devices (iPhones and iPads). View, Manage, and Find your Bluetooth devices easily.</p>
                    <Link style={{textDecoration: 'None', color: '#0398F4'}} to="/bluescope">
                        {/* <h3 style={{color: '#0398F4'}} >See all features</h3> */}
                        See all features
                    </Link>
                    <br />
                    <br />
                    <a href={appStoreCampaignUrlBlueScope} 
                        target={"_blank"} rel="noreferrer" title={"Download BlueScope® on the App Store"}>
                    <img alt={"Download BlueScope® on the App Store"} src={iOSAppStoreBadge}/></a>
                </article>
            </div>
        </section>

        {/* ******************** */}
        {/* Traffic Signs Wizard */}
        <section className={styles.about}>
            <div className={styles.aboutCenter}>  
                <article className={styles.aboutImg}>
                    <div className={styles.imgContainer}>
                        <Link to="/usatrafficsigns">
                            <img src={trafficwizard_img} alt="Traffic Signs Wizard" />
                        </Link>
                    </div>
                </article>
                
                <article className={styles.aboutInfo}>
                    <h4>Traffic Signs Wizard</h4>
                    <p>Comprehensive Reference and Guide for Traffic Signs in USA</p>
                    <Link style={{textDecoration: 'None', color: '#0398F4'}} to="/usatrafficsigns">
                        See all features
                    </Link>

                    <br />
                    <br />
                    <a href={appStoreCampaignUrlTrafficWizard} target={"_blank"} rel="noreferrer" 
                    title={"Download Traffic Signs Wizard on the App Store"}>
                    <img alt={"Download Traffic Signs Wizard on the App Store"} src={iOSAppStoreBadge}/></a>
                    
                </article>
            </div>
        </section>

        {/* ******************** */}
        {/* Action Timer for Mac */}
        <section className={styles.about}>
            <div className={styles.aboutCenter}>  
                <article className={styles.aboutImg}>
                    <div className={styles.imgContainer}>
                        <Link to="/actiontimer">
                            <img src={actiontimer_img} alt="ActionTimer for Mac" />
                        </Link>
                    </div>
                </article>
                
                <article className={styles.aboutInfo}>
                    <h4>ActionTimer for Mac</h4>
                    <p>Simple Automation!</p>
                    <p>Smart Timer that lets you run select actions at specific times with voice announcements.</p>
                    <Link style={{textDecoration: 'None', color: '#0398F4'}} to="/actiontimer">
                        See all features
                    </Link>

                    <br />
                    <br />
                    <a href={appStoreCampaignUrlActionTimer} target={"_blank"} rel="noreferrer" 
                    title={"Download ActionTimer on the Mac App Store"}>
                    <img alt={"Download ActionTimer on the Mac App Store"} src={macAppStoreBadge}/></a>
                    
                </article>
            </div>
        </section>
        </>
    )
}

export default Apps
