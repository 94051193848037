import React from 'react';
import Layout from '../components/layout';
import StyledHero from "../components/StyledHero";
import Apps from "../components/Apps";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import Seo from '../components/SEO';

const apps = (props) => {
  const { data } = props;
  console.log("apps props:", props);

  const seoImage = {
		src: getSrc(data.seoImage),
		height: data.seoImage.childImageSharp.gatsbyImageData.height,
		width: data.seoImage.childImageSharp.gatsbyImageData.width,
		alt: 'App development',
  };
  
  return (
    <Layout>
      <Seo
        siteTitle={'Apps'}
        seoTitle='Apps | Malaud LLC'
        path={props.location.pathname}
        metaImage={seoImage}
        description='Malaud LLC Mobile and Web Apps'
        keywords={[]}
        datePublished="12-24-2020"
        dateModified="11-01-2022"
      />            
      
      <StyledHero overlay={false} img={data.defaultBcg.childImageSharp.gatsbyImageData} />
      <Apps />
    </Layout>
  );
}

export default apps

export const appsquery = graphql`{
  defaultBcg: file(relativePath: {eq: "apps.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
  seoImage: file(relativePath: {eq: "apps.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 600)
    }
  }
}
`
